import { FCWithChildren } from '../../types/FCWithChildren';
import TopNavPortal from '../../components/layout/top-menu/TopNavPortal';
import TenantSelect from './TenantSelect';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../recoil/atoms/Clients';

export const AdminLayout: FCWithChildren = ({ children }) => {
  const currentClient = useRecoilValue(currentClientAtom);

  return (
    <>
      <TopNavPortal elementId="portal-top-nav-tenant">{!currentClient && <TenantSelect />}</TopNavPortal>
      {children}
    </>
  );
};
